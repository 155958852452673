// React
import { ReactElement } from 'react';
// Context
import { useGlobal } from '@utmb/contexts/GlobalContext';
// Design System
import { Spacer } from '@utmb/design-system/spacer/Spacer';
import { Font } from 'src/design-system/text/font';
// Modules
import classNames from 'classnames/bind';
// Interfaces
import { SliceTitleSubtitleView } from 'src/types';
import { Slice } from '@utmb/types/slice-handler';
// Styles
import styles from './section-header.module.scss';

const cx = classNames.bind(styles);

interface SectionHeaderProps extends SliceTitleSubtitleView {
    topPadding?: 'small' | 'default' | 'big';
    bottomPadding?: 'small' | 'default' | 'big';
}

export const SectionHeader = (props: Slice<SectionHeaderProps>): ReactElement => {
    const { id, title, subtitle, topPadding = 'default', bottomPadding = 'default', isDark } = props;

    const {
        global: {
            event: { isWS },
        },
    } = useGlobal();

    const titleCSS = cx(styles.title, {
        title_ws_color: isWS && isDark,
    });

    const sectionCSS = cx(styles.container, {
        t_small: topPadding === 'small',
        t_default: topPadding === 'default',
        t_big: topPadding === 'big',
        b_small: bottomPadding === 'small',
        b_default: bottomPadding === 'default',
        b_big: bottomPadding === 'big',
        is_dark: isDark,
    });

    return (
        <section id={id} className={sectionCSS}>
            {title && (
                <Font.FuturaMedium className={titleCSS} as="h2">
                    {title}
                </Font.FuturaMedium>
            )}
            {title && subtitle && <Spacer.size30 />}
            {subtitle && <Font.FuturaLight className={styles.subtitle}>{subtitle}</Font.FuturaLight>}
        </section>
    );
};
